import React, { ReactElement, useContext, useState } from "react";
import { connect, FormikContextType, useFormikContext } from "formik";
import { ZoekschermState, ExterneDossiersType } from "../infra/zoekscherm-schema";
import { SettingsContext, useAdviesboxDataRepository, DataGrid } from "adviesbox-shared";
import { UiName } from "../../shared/types";
import { ImportTakenOutput, StatusOptions } from "../../.generated/externekoppelingen/externekoppelingentypes";
import { externeKoppelingenDossiersColumns } from "./externekoppelingen-dossiers-columns";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ParamRouteContext from "../../shared/paramrouting/paramrouting-context";

const ExterneKoppelingenDossiers = ({
  showExterneDossiers,
  history
}: {
  formik: FormikContextType<ZoekschermState>;
  showExterneDossiers: boolean;
} & RouteComponentProps): ReactElement => {
  const { values } = useFormikContext<ZoekschermState>();
  const [gegevensOpgehaald, setGegevensOpgehaald] = useState(false);
  // ophalen van de gegevens
  const settings = useContext(SettingsContext);
  const url = `${settings.externeKoppelingenOrigin}/importtaken`;
  const { vestiging } = useContext(ParamRouteContext);

  /* istanbul ignore next */
  const mapStatus = (status: StatusOptions): string => {
    switch (status) {
      case StatusOptions.Confirmed:
        return "Bevestigd";
      case StatusOptions.New:
        return "Nieuw";
      case StatusOptions.Ready:
        return "Klaar voor import";
      case StatusOptions.Rejected:
        return "Afgewezen";
      case StatusOptions.Success:
        return "Succesvol";
      case StatusOptions.ValidationError:
        return "Validatie fout";
      default:
        return "Technische fout";
    }
  };

  /* istanbul ignore next */
  const { fetchData, loading } = useAdviesboxDataRepository<ImportTakenOutput, ZoekschermState>(url, {
    mapDlToUi: (_, res) => {
      if (res) {
        res.isValid = true;
        const zoekschermData: ZoekschermState = {
          ...values,
          externeDossiers:
            res.importTaken?.map(importTaak => {
              return {
                importTaakId: importTaak.importTaakId,
                koppelingKey: importTaak.koppelingKey,
                aanmaakDatum: importTaak.aanmaakDatum,
                koppelingNaam: importTaak.koppelingNaam,
                klantNaam: importTaak.klantnaam,
                status: mapStatus(importTaak.status)
              };
            }) ?? []
        };
        return zoekschermData;
      }
    },
    mapTargetToUiField: (): UiName | null => {
      return null;
    }
  });

  if (showExterneDossiers && !gegevensOpgehaald) {
    fetchData();
    setGegevensOpgehaald(true);
  }

  const rowProps = (
    externeDossier: ExterneDossiersType
  ): {
    onClick: () => void;
  } => ({
    onClick: /* istanbul ignore next */ (): void => {
      if (
        externeDossier.status === mapStatus(StatusOptions.Ready) ||
        externeDossier.status === mapStatus(StatusOptions.ValidationError)
      ) {
        history.push(
          `/vestiging/${vestiging}/import/${externeDossier.koppelingKey}/taak/${externeDossier.importTaakId}`
        );
      }
    }
  });
  // renderen van de gegevens
  return (
    <>
      {/* istanbul ignore next */ loading && <div className="loader"></div>}
      {!loading && gegevensOpgehaald && (
        <>
          <DataGrid
            className="search-table"
            name={"externeDossiers"}
            minRows={0}
            loading={false}
            resizable={false}
            sortable={true}
            initialSort="aanmaakDatum"
            columns={externeKoppelingenDossiersColumns}
            getTrProps={(
              _state: any,
              rowInfo: any
            ): {
              onClick: () => void;
            } => rowProps(rowInfo.original)}
          />
        </>
      )}
    </>
  );
};

export default connect<{ showExterneDossiers: boolean }, ZoekschermState>(withRouter(ExterneKoppelingenDossiers));
